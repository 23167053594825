require('./bootstrap');
require('./ajaxFormFiles');
require('./cart');
require('./timetable');
require('./clientZone');
require('./hallReservation');
require('./cookie-aggrement');

function bindMobileMenu() {
    const burgerButton = document.getElementById('mobile_menu_icon');

    if (burgerButton) {
        burgerButton.onclick = () => {
            const mobileMenu = document.getElementById('mobile_menu_burger');

            if (mobileMenu.classList.contains('show')) {
                mobileMenu.classList.remove('show');
                burgerButton.classList.remove('show');
            } else {
                mobileMenu.classList.add('show');
                burgerButton.classList.add('show');
            }
        };
    }
}

window.addEventListener('load', () => {
    $.datetimepicker.setLocale(document.documentElement.lang);

    const d = new Date();
    d.setFullYear(d.getFullYear() - 16);
    const strDate = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;

    $('.datepicker').datetimepicker({
        startDate: strDate,
        maxDate: strDate,
        format: 'Y-m-d',
        timepicker: false,
    });
});

bindMobileMenu();
