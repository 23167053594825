import axios from 'axios';

function toggleAjaxFormMessage(form, message, success, noScroll) {
    let messageNode = form.querySelectorAll('.ajax-message')[0];
    if (messageNode) {
        messageNode.classList.remove('success');
        messageNode.classList.remove('fail');
        messageNode.classList.add(success ? 'success' : 'fail');
        messageNode.innerHTML = message;
    } else {
        if (message) {
            messageNode = document.createElement('div');
            messageNode.classList.add('ajax-message');
            messageNode.classList.add(success ? 'success' : 'fail');
            messageNode.innerHTML = message;
            form.appendChild(messageNode);
        }
    }

    if (!noScroll && message) {
        window.scroll({
            top: messageNode.getBoundingClientRect().top + window.scrollY,
            behavior: 'smooth',
        });
    }
}

function bindAjaxAndFormFiles() {
    const forms = document.querySelectorAll('.ajax-form');
    for (const form of forms) {
        for (const fileInput of form.querySelectorAll('input[type=file]')) {
            const parent = fileInput.parentNode.parentNode;
            fileInput.onchange = function () {
                for (const addedFile of parent.querySelectorAll('.added-file')) {
                    addedFile.remove();
                }

                const template = parent.querySelector('.file-template')
                    .cloneNode(true);
                template.querySelector('.file-title').innerHTML = fileInput.files[0].name;
                template.classList.add('added-file');
                template.classList.remove('file-template');

                template.querySelector('.file-remover')
                    .addEventListener('click', () => {
                        for (const addedFile of parent.querySelectorAll('.added-file')) {
                            addedFile.remove();
                        }
                        fileInput.value = '';
                    });

                parent.appendChild(template);
            };
        }
    }

    document.addEventListener('submit', async (e) => {
        if (e.target && e.target.classList && e.target.classList.contains('ajax-form')) {
            e.preventDefault();
            const form = e.target;

            if (form.classList.contains('loading')) {
                return;
            }

            form.classList.add('loading');

            const url = form.action;
            const method = form.method.toLowerCase();
            const data = new FormData();

            for (let i = 0; i < form.elements.length; i++) {
                if (form.elements[i].type !== 'submit') {
                    if (form.elements[i].type === 'checkbox') {
                        if (form.elements[i].checked) {
                            data.append(form.elements[i].name, form.elements[i].value);
                        }
                    } else if (form.elements[i].type === 'file') {
                        data.append(form.elements[i].name, form.elements[i].files[0]);
                    } else {
                        data.append(form.elements[i].name, form.elements[i].value);
                    }
                }
            }

            const fields = form.querySelectorAll('.form-field');
            for (const el of fields) {
                const input = el.querySelector('input') ?? el.querySelector('select') ?? el.querySelector('textarea');

                if (input) {
                    el.classList.remove('has-error');
                }
            }

            const tempErrorFields = form.querySelectorAll('.field-error');
            for (const el of tempErrorFields) {
                el.classList.remove('show');
            }

            toggleAjaxFormMessage(form, '', true, true);

            try {
                let response;

                switch (method) {
                    case 'get':
                        response = await axios.get(url);
                        break;
                    case 'post':
                        response = await axios.post(url, data, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        });
                        break;
                }

                if (!response.data.data.continue) {
                    form.classList.remove('loading');
                }

                if (response.data.data.alert) {
                    // eslint-disable-next-line no-alert
                    alert(response.data.data.alert);
                }

                if (response.data.data.successMessage) {
                    toggleAjaxFormMessage(form, response.data.data.successMessage, true);
                }

                if (response.data.data.failMessage) {
                    toggleAjaxFormMessage(form, response.data.data.failMessage, false);
                }

                if (response.data.data.clickID) {
                    document.getElementById(`${response.data.data.clickID}`).click();
                }

                if (response.data.data.removeID) {
                    document.getElementById(`${response.data.data.removeID}`).remove();
                }

                if (response.data.data.showElementByID) {
                    document.getElementById(`${response.data.data.showElementByID}`).classList.add('show');
                }

                if (response.data.data.redirect) {
                    window.location.replace(response.data.data.redirect);
                } else if (response.data.data.reload) {
                    location.reload();
                }
            } catch (error) {
                form.classList.remove('loading');

                if (error.response && error.response && error.response.data && error.response.data.error && error.response.data.error.errors) {
                    const fields = form.querySelectorAll('.form-field');
                    const errors = error.response.data.error.errors;
                    let firstErrorField;

                    for (const el of fields) {
                        const input = el.querySelector('input') ?? el.querySelector('select') ?? el.querySelector('textarea');
                        const errorField = el.querySelector('.field-error');

                        if (input) {
                            el.classList.remove('has-error');

                            if (errorField) {
                                errorField.classList.remove('show');
                                errorField.innerHTML = '';
                            }

                            if (errors[input.name]) {
                                el.classList.add('has-error');

                                if (!firstErrorField) {
                                    firstErrorField = el;
                                }

                                if (errorField && errors[input.name][0]) {
                                    errorField.innerHTML = errors[input.name][0];
                                    errorField.classList.add('show');
                                }
                            }
                        }
                    }

                    if (errors.clickID) {
                        document.getElementById(`${errors.clickID[0]}`).click();
                    }

                    if (errors.removeID) {
                        document.getElementById(`${errors.removeID[0]}`).remove();
                    }

                    if (errors.showElementByID) {
                        document.getElementById(`${errors.showElementByID[0]}`).classList.add('show');
                    }

                    if (firstErrorField) {
                        window.scroll({
                            top: firstErrorField.getBoundingClientRect().top + window.scrollY,
                            behavior: 'smooth',
                        });
                    }
                }

                if (error.response && error.response.data && error.response.data.data) {
                    if (error.response.data.data.alert) {
                        // eslint-disable-next-line no-alert
                        alert(error.response.data.data.alert);
                    }

                    if (error.response.data.data.successMessage) {
                        toggleAjaxFormMessage(form, error.response.data.data.successMessage, true);
                    }

                    if (error.response.data.data.failMessage) {
                        toggleAjaxFormMessage(form, error.response.data.data.failMessage, false);
                    }

                    if (error.response.data.data.clickID) {
                        document.getElementById(`${error.response.data.data.clickID}`).click();
                    }

                    if (error.response.data.data.removeID) {
                        document.getElementById(`${error.response.data.data.removeID}`).remove();
                    }

                    if (error.response.data.data.showElementByID) {
                        document.getElementById(`${error.response.data.data.showElementByID}`).classList.add('show');
                    }

                    if (error.response.data.data.redirect) {
                        window.location.replace(error.response.data.data.redirect);
                    } else if (error.response.data.data.reload) {
                        location.reload();
                    }
                }
            }
        }
    });
}

bindAjaxAndFormFiles();
