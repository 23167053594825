(function () {
    function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        const expires = `expires=${d.toUTCString()}`;
        document.cookie = `${cname}=${cvalue};${expires};path=/`;
    }

    function getCookie(cname) {
        const name = `${cname}=`;
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }

    function eraseCookie(name) {
        document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    }

    document.querySelector('.cookie-floater').addEventListener('click', e => {
        e.preventDefault();
        document.getElementById('cookie-agreement').classList.toggle('cookie-agreement-open');

        if (window.innerWidth > 1080) {
            document.getElementById('cart_container').style.bottom = '190px';
        } else {
            document.getElementById('cart_toggle_button').style.top = '50px';
            document.getElementById('cart_toggle_button').style.bottom = 'unset';
            document.getElementById('cart_container').style.top = '90px';
            document.getElementById('cart_container').style.bottom = 'unset';
        }
    });

    const necessaryCookiesData = getCookie('necessary_cookies');
    const functionalCookiesData = getCookie('functional_cookies');
    const analyticCookiesData = getCookie('analytic_cookies');
    const cookiesAgreementBar = document.getElementById('cookie-agreement');
    const functionalCookies = document.getElementsByName('functional_cookies')[0];
    const analyticCookies = document.getElementsByName('analytic_cookies')[0];
    const customCookiesBtn = document.getElementById('cookies-custom');
    const allCookiesBtn = document.getElementById('cookies-all');

    if (!necessaryCookiesData) {
        cookiesAgreementBar.classList.add('cookie-agreement-open');

        if (window.innerWidth > 1080) {
            document.getElementById('cart_container').style.bottom = '190px';
        } else {
            document.getElementById('cart_toggle_button').style.top = '50px';
            document.getElementById('cart_toggle_button').style.bottom = 'unset';
            document.getElementById('cart_container').style.top = '90px';
            document.getElementById('cart_container').style.bottom = 'unset';
        }
    }

    if (functionalCookiesData) {
        functionalCookies.checked = true;
    }

    if (analyticCookiesData) {
        analyticCookies.checked = true;
    }

    const cookieAgreements = {
        functional_cookies: functionalCookies.checked,
        analytic_cookies: analyticCookies.checked,
    };

    functionalCookies.addEventListener('click', () => {
        cookieAgreements.functional_cookies = functionalCookies.checked;
    });

    analyticCookies.addEventListener('click', () => {
        cookieAgreements.analytic_cookies = analyticCookies.checked;
    });

    allCookiesBtn.addEventListener('click', () => {
        setCookie('necessary_cookies', 1, 360);
        setCookie('functional_cookies', 1, 360);
        setCookie('analytic_cookies', 1, 360);
        cookieAgreements.functional_cookies = true;
        cookieAgreements.analytic_cookies = true;
        functionalCookies.checked = true;
        analyticCookies.checked = true;
        cookiesAgreementBar.classList.remove('cookie-agreement-open');

        document.getElementById('cart_toggle_button').style.top = '';
        document.getElementById('cart_toggle_button').style.bottom = '';
        document.getElementById('cart_container').style.top = '';
        document.getElementById('cart_container').style.right = '';
        document.getElementById('cart_container').style.bottom = '';
    });

    customCookiesBtn.addEventListener('click', () => {
        setCookie('necessary_cookies', 1, 360);
        if (cookieAgreements.analytic_cookies) {
            setCookie('analytic_cookies', 1, 360);
        } else {
            eraseCookie('analytic_cookies');
        }
        if (cookieAgreements.functional_cookies) {
            setCookie('functional_cookies', 1, 360);
        } else {
            eraseCookie('functional_cookies');
        }
        cookiesAgreementBar.classList.remove('cookie-agreement-open');

        document.getElementById('cart_toggle_button').style.top = '';
        document.getElementById('cart_toggle_button').style.bottom = '';
        document.getElementById('cart_container').style.top = '';
        document.getElementById('cart_container').style.right = '';
        document.getElementById('cart_container').style.bottom = '';
    });
})();
