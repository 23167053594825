function bindPaidSelect() {
    const select = document.querySelector('select[name="group_paid"]');

    if (select) {
        select.onchange = function () {
            const cleanUrl = window.location.href
                .replaceAll('?p=1', '')
                .replaceAll('?p=2', '')
                .replaceAll('?p=3', '')
                .replaceAll('&p=1', '')
                .replaceAll('&p=2', '')
                .replaceAll('&p=3', '');

            if (window.location.href.indexOf('?w=') > 0) {
                window.location = `${cleanUrl}&p=${select.value}`;
            } else {
                window.location = `${cleanUrl}?p=${select.value}`;
            }
        };
    }
}

let currentTtViewIndex = 1;

function setupNewTimetableWeekdayView(index) {
    if (index < 0 && currentTtViewIndex > 1) {
        currentTtViewIndex += index;
    } else if (index > 0 && currentTtViewIndex < 6) {
        currentTtViewIndex += index;
    }

    const weekTargets = [
        `week-day-${currentTtViewIndex}`,
        `week-day-${currentTtViewIndex + 1}`,
    ];

    if (window.innerWidth > 560) {
        weekTargets.push(`week-day-${currentTtViewIndex + 2}`);
    }

    if (window.innerWidth > 710) {
        weekTargets.push(`week-day-${currentTtViewIndex + 3}`);
    }

    if (window.innerWidth > 870) {
        weekTargets.push(`week-day-${currentTtViewIndex + 4}`);
    }

    if (window.innerWidth > 1010) {
        weekTargets.push(`week-day-${currentTtViewIndex + 5}`);
    }

    const x = window.scrollX;
    const y = window.scrollY;

    const tableHeaders = document.querySelectorAll('.header-th, .timetable-cell');
    for (const header of tableHeaders) {
        let hide = false;

        header.classList.remove('tt-mobile-hidden');
        header.classList.remove('tt-mobile-show');

        for (const target of weekTargets) {
            if (header.classList.contains(target)) {
                hide = true;
            }
        }

        if (hide) {
            header.classList.remove('tt-mobile-hidden');
            header.classList.add('tt-mobile-show');
        } else {
            header.classList.add('tt-mobile-hidden');
            header.classList.remove('tt-mobile-show');
        }
    }

    window.scrollTo(x, y);
}

function timetableSlidePrevious() {
    const button = document.getElementById('mobile_tt_previous');
    if (button) {
        button.onclick = function () {
            setupNewTimetableWeekdayView(-1);
        };
    }
}

function timetableSlideNext() {
    const button = document.getElementById('mobile_tt_next');

    if (button) {
        button.onclick = function () {
            if (window.innerWidth > 560 && currentTtViewIndex === 5 ||
                window.innerWidth > 710 && currentTtViewIndex === 4 ||
                window.innerWidth > 870 && currentTtViewIndex === 3 ||
                window.innerWidth > 1010 && currentTtViewIndex === 2) {
                return;
            }

            setupNewTimetableWeekdayView(1);
        };
    }
}

function bindTableOnResize() {
    window.onresize = function () {
        currentTtViewIndex = 1;
        setupNewTimetableWeekdayView(0);
    };
}

bindTableOnResize();
bindPaidSelect();
timetableSlideNext();
timetableSlidePrevious();
